<template>
  <div>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="addLicence"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.add') }} Licence
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ name: 'partners-view', params: { id: partner.id } })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>
    <template v-if="show_form">
    
    <hr>

    <validation-observer
        ref="refFormObserver"
      >
        <!-- User Info: Input Fields -->
        <b-form
          style="margin:20px 0;"
        >
          <b-row>

            <!-- Field: Name -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
                :custom-messages="{ required: $t('validators.required') }"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="name"
                    v-model="form_name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Name"
                    maxlength="30"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required"
                :custom-messages="{ required: $t('validators.required') }"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="email"
                    v-model="form_email"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    maxlength="50"
                    placeholder="Email"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Password -->
            
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Password"
                rules="required|password"
                :custom-messages="{ required: $t('validators.required') }"
              >
                <b-form-group 
                    :state="getValidationState(validationContext)"
                >
                  <label for="password">Password</label>
                  <b-input-group>
                    <b-form-input
                      id="pass"
                      :type="passwordFieldType"
                      v-model="form_pass"
                      :state="getValidationState(validationContext)"
                      trim
                      maxlength="20"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <div class="invalid-feedback mb-1" 
                  :style="feedbackStyle($store.getters['app/currentBreakPoint'])">
                  {{ validationContext.errors[0] }}
                </div>
              </validation-provider>


            </b-col>


          </b-row>
        </b-form>
    </validation-observer>
      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="button"
        @click="submitChanges"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('forms.save_changes') }}
      </b-button>
      <b-button
        variant="outline-secondary"
        type="button"
        @click="closeForm"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('forms.cancel') }}
      </b-button>
    </template>

    <hr>

    <b-row>
      <b-col
        cols="12"
        lg="6">
          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-body>

              <template v-for="user in linked_users">
                <div
                  :key="user.id"
                  class="employee-task d-flex justify-content-between align-items-center"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar
                        size="42"
                        :text="avatarText(user.name)"
                      />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        <b-link
                          class="alert-link"
                          :to="{ name: 'licences-view', params: { id: user.id } }"

                        > {{ user.name }}
                        </b-link>
                      </h6>
                      <small class="text-muted ">{{ user.email }} </small>
                    </b-media-body>
                  </b-media>
                  <div class="d-flex align-items-center pointer">
                    <span @click="setUser(user.id)">
                      <small class="m-75" style="color:green;">
                        <font-awesome-icon :icon="['fas', 'edit']"
                        size="lg" />
                      </small>
                    </span>
                    
                    <span @click="removeUser(user.id)">
                      <small class="m-75" style="color:tomato;">
                        <font-awesome-icon :icon="['fas', 'trash-alt']"
                        size="lg" />
                      </small>
                    </span>
                  </div>
                </div>
              </template>
            </b-card-body>
          </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { detachLicenceFromPartner, createLicence, editPartner } from '@/api/routes'

import {
  BLink, BCardBody, BFormDatepicker, BInputGroup,BInputGroupAppend,BMediaBody,BMediaAside,
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { avatarText } from '@core/utils/filter'

export default {
  components:{  
    BButton,
    BLink,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormDatepicker,
    BMediaAside,
    BMediaBody,
    vSelect,

     // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,

      avatarText
    }
  },
  props:{
    linked_users: {
      type: Array,
      required: true
    },
    partner: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      required,
      alphaNum,
      password,

      show_form: false,
      form_id: null,
      form_name: null,
      form_email: null,
      form_pass: '',


    }
  },
  computed:{
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    addLicence(){
      this.show_form = true;
      this.form_id = null;
      this.form_name = null;
      this.form_email = null;
      this.form_pass = '';
    },
    closeForm(){
      this.show_form = false;
      this.form_id = null;
      this.form_name = null;
      this.form_email = null;
      this.form_pass = '';
    },
    setUser(user_id){
      this.linked_users.map( x => {
        if(x.id == user_id){
          this.form_id = x.id;
          this.form_name = x.name
          this.form_email = x.email
          this.form_pass = '';
        }
      })
      this.show_form = true;
    },
    removeUser(id){
      //eliminar school
       this.$bvModal
        .msgBoxConfirm(this.$t('table.delete_one_confirmation')+' '+this.$t('forms.delete_structure.disclaimer'), {
          title: this.$t('forms.delete_model', {model:this.$t('models.licence')}),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'user_id': id
            }
            this.$http.post(detachLicenceFromPartner, data).then( response =>{
              if(response.data.status == 200){
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
                this.closeForm()
              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    

    },
    validate(){
      let validated = true
      if(!this.form_name){
        this.makeToast('danger', this.$t('Error'), 'Name missing');
        validated = false
      }
      if(!this.form_email){
        this.makeToast('danger', this.$t('Error'), 'Email missing');
        validated = false
      }
      if(!this.form_pass){
        this.makeToast('danger', this.$t('Error'), 'Password missing');
        validated = false
      }
      return validated
    },
    async submitChanges(){
      if(this.validate()){

        //create
        let route = createLicence
        let date = new Date();
        let yesterday = Math.round(date.setDate(date.getDate() - 1)/1000);
        //var yesterday_s = new Date(yesterday).toLocaleString()
  
        let data = {}
        if(this.form_id){
          //edit
          route = editPartner
          data = { 
            'tab': 'users',
            'id': this.partner.id,
            'user_id': this.form_id,
            'fullName': this.form_name,
            'username': this.form_email,
            'new_pass': this.form_pass
          }
        } else {
          data = {
            'context': 'partner',
            'fullName': this.form_name,
            'username': this.form_email,
            'password': this.form_pass,
            'max_children': this.partner.users_defaults.max_children,
            'emailing': this.partner.users_defaults.emailing,
            'welcomed': this.partner.users_defaults.welcomed,
            'zero_emailing': this.partner.users_defaults.zero_emailing,
            'allowed_access_la': this.partner.users_defaults.allowed_access_la,
            'lang': this.partner.users_defaults.lang_id,
            'role': 3,
            'currentPlan': this.partner.id,
            'valid_from': yesterday,
            'valid_until': yesterday,
          }
        }
        await this.$http.post(route, data).then( response => {
          if(response.data.status == 200){
            this.$emit('refetch-data');
            this.makeToast('success', this.$t('Success'), response.data.msg);
  
            this.closeForm();
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      }
    },
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
  }
}
</script>

<style>

</style>