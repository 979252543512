<template>
  <div>
    <validation-observer
      ref="refFormObserver"
    >
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            :src="recordData.logo_url"
            :text="avatarText(recordData.description)"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
        </template>
        <div class="d-flex flex-wrap">
          <b-button
            @click="$emit('toggle-logo-upload')"
            variant="primary"
          >{{ $t('forms.update') }}</b-button>
        </div>
      </b-media>
      
      <!-- FORM  -->
      <b-form class="account-form">
        <b-row>

          <!-- Field: Description -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Description"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  v-model="recordData.description"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Description"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>          

          <!-- Field: Support Email -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Support Email"
              rules=""
            >
              <b-form-group
                label="Support Email"
                label-for="support_email"
              >
                <b-form-input
                  id="support_email"
                  v-model="recordData.support_email"
                  :state="getValidationState(validationContext)"
                  
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Bundles url -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Bundles url"
              rules=""
            >
              <b-form-group
                label="Bundles url"
                label-for="bundles_url"
              >
                <b-form-input
                  id="bundles_url"
                  v-model="recordData.bundles_url"
                  :state="getValidationState(validationContext)"
                  
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: bundles url legacy -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Bundles url legacy"
              rules=""
            >
              <b-form-group
                label="Bundles url legacy"
                label-for="bundles_url_legacy"
              >
                <b-form-input
                  id="bundles_url_legacy"
                  v-model="recordData.bundles_url_legacy"
                  :state="getValidationState(validationContext)"
                  
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        <!-- Field: Total Licenses -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
            #default="validationContext"
            :name="$t('dash.cards.total_licences')"
            rules="integer"
          >
            <b-form-group
              :label="$t('dash.cards.total_licences')"
              label-for="total_licenses"
            >
              <b-form-input
                id="total_licenses"
                type="number"
                min="0"
                v-model="recordData.total_licenses"
                :state="getValidationState(validationContext)"
                
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          </b-col>

        <!-- canEdit -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
            >
              <b-form-checkbox
                v-model="recordData.canEdit"
                :value="1"
                :unchecked-value="0"
              >
                canEdit
              </b-form-checkbox>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <b-col>
            <h4>API Token</h4>
              <b-form-textarea
                v-on:focus="$event.target.select()"
                ref="api_token"
                id="api_token"
                readonly
                rows="1"
                v-model="recordData.api_token"                
              />
              <b-button
                variant="secondary"
                class="mt-1 mr-0 mr-sm-1"
                type="button"
                @click="createToken"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                {{ $t('forms.generate') }} Token
              </b-button>
              <b-button
                variant="outline-secondary"
                class="mt-1"
                type="button"
                @click="copyToken"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                {{ $t('forms.copy') }} Token
              </b-button>
          </b-col>
        </b-row>
        

        <b-row>
          <b-col>
            <h4>Users Defaults</h4>
          </b-col>
        </b-row>

        <b-row>
  
          <!-- Field: Max Children -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('max_children')"
              rules="integer|required"
              :custom-messages="{ required: $t('validators.required') }"
            >
            <b-form-group
              :label="$t('max_children')"
              label-for="max_children"
            >
              <b-form-input
                id="max_children"
                type="number"
                min="0"
                v-model="recordData.users_defaults.max_children"
                :state="getValidationState(validationContext)"
                :disabled="resolveMaxChildrenByRole"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>
            
            <!-- Field: Language -->
            
            <validation-provider
              #default="validationContext"
              :name="$t('language')"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                :label="$t('language')"
                label-for="lang"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  label="name"
                  v-model="recordData.users_defaults.lang_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="langsOptions"
                  :clearable="false"
                  input-id="lang"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col>
            <b-row>
              <!-- emailing -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                >
                  <b-form-checkbox
                    v-model="recordData.users_defaults.emailing"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Emailing
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <!-- welcomed -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                >
                  <b-form-checkbox
                    v-model="recordData.users_defaults.welcomed"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Welcomed
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <!-- zero_emailing -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                >
                  <b-form-checkbox
                    v-model="recordData.users_defaults.zero_emailing"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Zero Emailing
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              
              <!-- allowed_access_la -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                >
                  <b-form-checkbox
                    v-model="recordData.users_defaults.allowed_access_la"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Allowed Access to Learning Analytics
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>


        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="submitChanges"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ name: 'partners-view', params: { id: recordData.id } })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>
  </div>
</template>

<script>
import { createPartnerToken, editPartner } from '@/api/routes'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback,BFormTextarea, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import { decryptData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    recordData: {
      type: Object,
      required: true,
    },
    langsOptions: {
      type: Array,
      required: true,
    },
  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('integer', {
      ...integer,
      message: this.$t('forms.errors.integer'),
    });
  },
  data(){
    return {
      required,
      integer
    }
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    copyToken(){
      this.$refs.api_token.focus();
      //document.execCommand('copy');
      navigator.clipboard.writeText(this.recordData.api_token);
      this.makeToast('success', this.$t('Success'), 'Copied to clipboard!');
    },
    async createToken(){
      let data = {
        'id': this.recordData.id,
      }
      await this.$http.post(createPartnerToken, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), response.data.msg);
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    async submitChanges(){
      //// console.log(this.recordData)
      /* let users_defaults = `{"emailing": ${this.recordData.users_defaults.emailing || 0},"lang": "${this.recordData.users_defaults.lang}","max_children": ${this.recordData.users_defaults.max_children},"welcomed": ${this.recordData.users_defaults.welcomed || 0},"zero_emailing": ${this.recordData.users_defaults.zero_emailing  || 0},"allowed_access_la": ${this.recordData.users_defaults.allowed_access_la || 0}}` */

      let users_def = {...this.recordData.users_defaults}
      users_def.lang_id = this.recordData.users_defaults.lang_id.id
      let data = {
          'tab': 'account',
          'id': this.recordData.id,
          'description':this.recordData.description,
          'total_licenses':this.recordData.total_licenses,
          'support_email':this.recordData.support_email,
          'bundles_url':this.recordData.bundles_url,
          'bundles_url_legacy':this.recordData.bundles_url_legacy,
          'canEdit':this.recordData.canEdit,
                      
          'users_defaults': users_def,
      }
      // console.log('data edit partner', data)
      await this.$http.post(editPartner, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
      
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      avatarText,

      // form validation
      refFormObserver,
      getValidationState,

    }
  },
  computed:{
    resolveMaxChildrenByRole(){
      let loggedUser = decryptData(localStorage.getItem('userData'))
      if(loggedUser.role == "admin"){
        return false
      } else {
        return true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.account-form .row{
  margin-bottom: 20px;
}
</style>
