<template>
  <div>
    <!--SIDEBAR MODAL DE ADD-NEW -->
    <add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :countries-options="countriesOptions"
      :plan-options="planOptions"
      @refetch-data="$emit('refetch-data')"
    />
    <b-row>
      <b-col
        cols="12">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="button"
          @click="isAddNewSidebarActive = true"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.add_model', {model: $t('models.school')})}}

        </b-button>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="hasHistory() 
            ? $router.go(-1) 
            : $router.push({ name: 'partners-view', params: { id: partner.id } })"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.go_back') }}
        </b-button>
      </b-col>
    </b-row>
    

    <b-row>
      <b-col
        cols="12"
        lg="6">
          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-body>

              <template v-for="school in schools">
                <div
                  :key="school.id"
                  class="employee-task d-flex justify-content-between align-items-center"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar
                        variant="primary"
                        :src="school.logo_url"
                        size="42"
                        :text="avatarText(school.name)"
                      />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        <b-link
                          class="alert-link"
                          :to="{ name: 'school-view', params: { id: school.id } }"

                        > {{ school.name }}
                        </b-link>

                      </h6>
                      <small class="text-muted ">{{ school.email }} </small>
                    </b-media-body>
                  </b-media>
                  <div class="d-flex align-items-center pointer">
                    <b-link
                      class="alert-link"
                      :to="{ name: 'school-edit', params: { id: school.id } }"

                    >
                      <small class="m-75" style="color:green;">
                        <font-awesome-icon :icon="['fas', 'edit']"
                        size="lg" />
                      </small>
                    </b-link>
                    
                    <span @click="removeSchool(school.id)">
                      <small class="m-75" style="color:tomato;">
                        <font-awesome-icon :icon="['fas', 'trash-alt']"
                        size="lg" />
                      </small>
                    </span>
                  </div>
                </div>
              </template>
            </b-card-body>
          </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { deleteSchool } from '@/api/routes'

import {
  BLink, BCardBody, BFormDatepicker, BInputGroup,BInputGroupAppend,BMediaBody,BMediaAside,
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import AddNew from '@/views/schools/AddNew.vue'

export default {
  components:{  
    BLink,
    BButton,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormDatepicker,
    BMediaAside,
    BMediaBody,
    vSelect,

     // Form Validation
    ValidationProvider,
    ValidationObserver,

    AddNew
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,

      avatarText
    }
  },
  props:{
    schools: {
      type: Array,
      required: true
    },
    partner: {
      type: Object,
      required: true,
    },
    countriesOptions: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      required,
      alphaNum,
      password,

      show_form: false,
     /*  form_id: null,
      form_name: null,
      form_email: null,
      form_pass: '', */

      isAddNewSidebarActive: false,
      planOptions:[]


    }
  },
  mounted(){
    this.planOptions = [
              { 'value': this.partner.id, 'label': this.partner.description, 'can_select': true }
            ]
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    setSchool(school_id){
      this.schools.map( x => {
        if(x.id == school_id){
          this.form_id = x.id;
          this.form_name = x.name
          this.form_email = x.email
          this.form_pass = '';
        }
      })
      this.show_form = true;
    },
    removeSchool(id){
      //eliminar school
       this.$bvModal
       .msgBoxConfirm(this.$t('schools.sure'), {
              title: this.$t('schools.delete'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: this.$t('Yes'),
              cancelTitle: this.$t('No'),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
        .then(value => {
          if(value){
            let data = {
              'school_id': id
            }
            this.$http.post(deleteSchool, data).then( response =>{
              if(response.data.status == 200){
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
                this.closeForm()
              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    

    },
  }
  
}
</script>

<style>

</style>